import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 *
 *
 * @export
 * @interface LayoutState
 */
export interface LayoutState {
    staticMenuDesktopInactive: boolean;
    overlayMenuActive: boolean;
    profileSidebarVisible: boolean;
    configSidebarVisible: boolean;
    staticMenuMobileActive: boolean;
    menuHoverActive: boolean;
    configSidebarPosition: string;
    configSidebarStyleClass: string;
    configSidebarCloseOnEscape: boolean
    configShowCloseIcon: boolean
    configTransitionOptions: string
    menuMode: string
}

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    /**
     *
     *
     * @type {LayoutState}
     * @memberof LayoutService
     */
    state: LayoutState = {
        staticMenuDesktopInactive: false, // If configSidebarVisible is true, this value is false IMPORTANT
        overlayMenuActive: false,
        profileSidebarVisible: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false,
        configSidebarPosition: 'left',
        configSidebarStyleClass: 'gray-color',
        configSidebarCloseOnEscape: false,
        configShowCloseIcon: false,
        configTransitionOptions: '1500ms cubic-bezier(0, 0, 0.2, 1)',
        menuMode: 'overlay'
    };

    private overlayOpen = new Subject<any>();

    overlayOpen$ = this.overlayOpen.asObservable();
    isBrowser:any = isPlatformBrowser(this.platformId);;
    constructor(@Inject(PLATFORM_ID) private platformId: object){
    }

    /**
     *
     *
     * @param {*} {
     *             configSidebarPosition = 'left', 
     *             configSidebarStyleClass = '', 
     *             configSidebarCloseOnEscape = false, 
     *             configShowCloseIcon = false, 
     *             menuMode = 'static'
     *         }
     * @memberof LayoutService
     */
    onMenuToggle(
        {
            configSidebarPosition = 'left',
            configSidebarStyleClass = '',
            configSidebarCloseOnEscape = false,
            configShowCloseIcon = false,
            menuMode = 'static'
        },
        keepVisible?: boolean
    ) {
        if (this.isOverlay()) {
            this.state.overlayMenuActive = !this.state.overlayMenuActive;
            if (this.state.overlayMenuActive) {
                this.overlayOpen.next(null);
            }
        }
        if (this.isDesktop()) {
            // this.state.staticMenuDesktopInactive = keepVisible!;
            this.state.configSidebarPosition = configSidebarPosition
            this.state.configSidebarStyleClass = configSidebarStyleClass
            this.state.configSidebarVisible = keepVisible!;
            this.state.configSidebarCloseOnEscape = configSidebarCloseOnEscape;
            this.state.configShowCloseIcon = configShowCloseIcon;
            this.state.menuMode = menuMode
            this.state.overlayMenuActive = menuMode === 'overlay'

        } else {
            this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;
            this.state.configSidebarPosition = configSidebarPosition
            this.state.configSidebarStyleClass = configSidebarStyleClass
            this.state.configSidebarVisible = !this.state.configSidebarVisible;
            this.state.configSidebarCloseOnEscape = true;
            this.state.configShowCloseIcon = true;
            this.state.menuMode = 'overlay'
            this.state.overlayMenuActive = true

            if (this.state.staticMenuMobileActive) {
                this.overlayOpen.next(null);
            }
        }
    }

    /**
     *
     *
     * @memberof LayoutService
     */
    showProfileSidebar() {
        this.state.profileSidebarVisible = !this.state.profileSidebarVisible;
        if (this.state.profileSidebarVisible) {
            this.overlayOpen.next(null);
        }
    }

    isOverlay() {
        return this.state.menuMode === 'overlay';
    }

    isMobile() {
        return !this.isDesktop();
    }

    isDesktop() {
        if(this.isBrowser){
            return window.innerWidth > 1200;
        }
        return 
    }

}
