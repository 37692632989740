/**
 * Application Environment Configuration
 *
 * This constant represents the environment configuration for the application. It is typically used
 * to store environment-specific settings such as API endpoints, feature flags, and other parameters.
 *
 * @type {Object}
 */
export const environment = {
    production: true,
    // api:'https://api.tolima.travel',
    api: 'https://api.v2.tolima.travel',
    token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6IiIsImV4cCI6MTU0NDI5MjYwNiwib3JpZ19pYXQiOjE1NDA4MzY2MDYsInVzZXJfaWQiOjIsInVzZXJuYW1lIjoiYW5vbmltbyJ9.FW2GzE5BjVIVXUeq7ZtMb2HQQg_xjfXs-6UlXl_npZs',
    sentryMode: 'production',
    apiKeyMap: 'AIzaSyCaKbVhcX_22R_pRKDYuNA7vox-PtGaDkI',
    apiKeyRoutesMap: 'AIzaSyBOiqaIXuqnO4aoDQaX-BPZKI-sCcgcePg',
    openAiToken: 'sk-proj-t5OehjGOSmOsfjfOiIVZT3BlbkFJPM0JQBbpkL4qAOfuN8q9',
    baseUrl: 'https://tolima.travel',
    wompiApi: 'pub_prod_g6SYvw9du0RHK5DO7vILHFSMn60paMUn',
    wompiIntegrity: 'prod_integrity_oxvrK4jC5WUDk1bJmAIUUqmuTQLVHyWJ'
};
