import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LayoutService } from './core/services/app.layout.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@tt/environments/environment';
import { SeoService } from './shared/services/seo.service';

/**
 *
 * App component example doc
 * 
 * @export
 * @class AppComponent
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent{
/**
 * A subscription to an event or observable related to the overlay menu open state.
 *
 * @type {*}
 * @memberof AppComponent
 */
overlayMenuOpenSubscription: any;


  /**
   * Creates an instance of AppComponent.
   * @param {LayoutService} layoutService  - An instance of the LayoutService.
   * @param {PrimeNGConfig} primengConfig - An instance of the PrimeNGConfig for configuring PrimeNG components.
   * @memberof AppComponent 
   * @memberof AppComponent
   */
  constructor( public layoutService: LayoutService, public primengConfig: PrimeNGConfig, private router: Router, public seoService: SeoService, ){
    
    this.primengConfig.setTranslation({
      "startsWith": "Comience con",
      "contains": "Contenga",
      "notContains": "No contenga",
      "endsWith": "Termine con",
      "equals": "Igual a",
      "notEquals": "Diferente a",
      "noFilter": "Sin filtro",
      "lt": "Menor que",
      "lte": "Menor o igual a",
      "gt": "Mayor que",
      "gte": "Mayor o igual a",
      "dateIs": "Fecha igual a",
      "dateIsNot": "Fecha diferente a",
      "dateBefore": "Fecha antes de",
      "dateAfter": "Fecha después de",
      "clear": "Limpiar",
      "apply": "Aplicar",
      "matchAll": "Coincidir todo",
      "matchAny": "Coincidir con cualquiera",
      "addRule": "Agregar regla",
      "removeRule": "Eliminar regla",
      "accept": "Sí",
      "reject": "No",
      "choose": "Escoger",
      "upload": "Subir",
      "cancel": "Cancelar",
      "fileSizeTypes": [
        "B",
        "KB",
        "MB",
        "GB",
        "TB",
        "PB",
        "EB",
        "ZB",
        "YB"
      ],
      "dayNames": [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado"
      ],
      "dayNamesShort": [
        "Dom",
        "Lun",
        "Mar",
        "Mié",
        "Jue",
        "Vie",
        "Sáb"
      ],
      "dayNamesMin": [
        "D",
        "L",
        "M",
        "M",
        "J",
        "V",
        "S"
      ],
      "monthNames": [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      "monthNamesShort": [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic"
      ],
      "today": "Hoy",
      "weekHeader": "Sem",
      "firstDayOfWeek": 1,
      "dateFormat": "dd/mm/yy",
      "weak": "Débil",
      "medium": "Medio",
      "strong": "Fuerte",
      "passwordPrompt": "Escriba una contraseña",
      "emptyFilterMessage": "Sin opciones disponibles",
      "emptyMessage": "No se han encontrado resultados",
      "aria": {
        "trueLabel": "Verdadero",
        "falseLabel": "Falso",
        "nullLabel": "No seleccionado",
        "star": "1 estrella",
        "stars": "{star} estrellas",
        "selectAll": "Seleccionar todos",
        "unselectAll": "Deseleccionar todos",
        "close": "Cerrar",
        "previous": "Anterior",
        "next": "Siguiente",
        "navigation": "Navegación",
        "scrollTop": "Desplazarse hacia arriba",
        "moveTop": "Mover arriba",
        "moveUp": "Subir",
        "moveDown": "Bajar",
        "moveBottom": "Desplazarse hacia abajo",
        "moveToTarget": "Mover al objetivo",
        "moveToSource": "Mover al origen",
        "moveAllToTarget": "Mover todo al objetivo",
        "moveAllToSource": "Mover todo al origen",
        "pageLabel": "Página {page}",
        "firstPageLabel": "Primera Página",
        "lastPageLabel": "Última Página",
        "nextPageLabel": "Siguiente Página",
        "previousPageLabel": "Página Anterior",
        "rowsPerPageLabel": "Filas por página",
        "jumpToPageDropdownLabel": "Ir al menú desplegable de página",
        "jumpToPageInputLabel": "Ir a la entrada de página",
        "selectRow": "Seleccionar fila",
        "unselectRow": "Desmarcar fila",
        "expandRow": "Expandir Fila",
        "collapseRow": "Reducir Fila",
        "showFilterMenu": "Mostrar menú del filtro",
        "hideFilterMenu": "Ocultar menú del filtro",
        "filterOperator": "Operador de filtro",
        "filterConstraint": "Restricción de filtro",
        "editRow": "Editar fila",
        "saveEdit": "Guardar editado",
        "cancelEdit": "Cancelar editado",
        "listView": "Vista de lista",
        "gridView": "Vista de cuadrícula",
        "slide": "Deslizar",
        "slideNumber": "{slideNumber}",
        "zoomImage": "Ampliar imagen",
        "zoomIn": "Ampliar",
        "zoomOut": "Reducir",
        "rotateRight": "Girar a la derecha",
        "rotateLeft": "Girar a la izquierda"
      }
    }
    )
    const description = 'Viaja, explora y conoce el Tolima con nosotros, somos una plataforma turistica en la que podrás ver todo el turismo del Tolima'
    const title = 'Tolima Travel | Plataforma turística'
    const image = `../assets/images/Logo_TT.png`
    this.seoService.updateTitle(`${title}`);
    this.seoService.updateMetaTags([
      {name:'title',content:title},
      {name:'description',content:description},
      {property:'og:title',content:title},
      {property:'og:description',content:description},
      {property:'og:image',content:image},
      {name:'twitter:description',content:description},
      {name:'twitter:title',content:title},
      {name:'twitter:image',content:image},
    ])
    this.router.events.subscribe((event:any) => {
      if (event?.routerEvent &&  event?.routerEvent instanceof NavigationEnd) {
        const currentUrl = event?.routerEvent.urlAfterRedirects;
        const url = `${environment.baseUrl}${currentUrl}`
        this.seoService.updateCanonicalLink(url);
        this.seoService.updateMetaTags([
          {property:'url',content:url},
          {property:'og:url',content:url},
          {name:'twitter:url',content:url},
        ])
      }
    });
  }

/**
 * A method for generating a test error to test Sentry error tracking.
 * Throws an error with the message "Sentry Test Error."
 *
 * @memberof AppComponent
 */
public throwTestError(): void {
  throw new Error("Sentry Test Error");
}

  
  /**
   *
   * Front end tittle
   * @memberof AppComponent
   */
  title = 'tolima_travel';

}
