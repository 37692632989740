import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private renderer!: Renderer2
  constructor(private meta: Meta, private title: Title, rendererFactory: RendererFactory2, @Inject(DOCUMENT)private document: Document) { 
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateMetaTags(tags: { content: string,  name?: string, property?:string }[]) {
    tags.forEach(tag => {
      if(tag.property){
        this.meta.updateTag({ property: tag.property, content: tag.content });
      }else if(tag.name){
        this.meta.updateTag({ name: tag.name, content: tag.content });
      }
      
    });
  }

  // updateLinkTags(tags: { rel: string, href: string }[]) {
  //   tags.forEach(tag => {
  //     this.meta.updateTag({ rel: tag.rel, content: tag.href });
  //   });
  // }

  updateCanonicalLink(newCanonicalUrl: string) {
    const canonicalLink = this.document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      this.renderer.setAttribute(canonicalLink, 'href', newCanonicalUrl);
    }
  }
}
