import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { ErrorService } from '@tt/app/shared/services/error.service';

/**
 *
   * Intercepts HTTP requests for error handling.
   *
   * This interceptor allows to catch errors in HTTP requests and to perform additional actions
   * additional actions before the request is handled by the server or after a response has been received.
   * a response has been received.
   * 
   * @export
   * @class InterceptorErrorInterceptor
   * @implements {HttpInterceptor}
   */
@Injectable()
export class InterceptorErrorInterceptor implements HttpInterceptor {

 
  /**
   * The constructor function takes an instance of the ErrorService class as a parameter.
   * @param {ErrorService} errorService - The errorService parameter is of type ErrorService and is
   * marked as private. It is likely a dependency that is injected into the constructor of a class.
   * This allows the class to use the functionality provided by the ErrorService.
   */
  constructor(private errorService: ErrorService) { }

  /**
   *
   * 
   * @param {HttpRequest<unknown>} request - The incoming HTTP request.
   * @param {HttpHandler} next - The next handler in the interceptor chain.
   * @return {*}  {Observable<HttpEvent<unknown>>} - An Observable that emits HTTP events, including server responses or captured errors.
   * server responses or captured errors.
   * @memberof InterceptorErrorInterceptor
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        var errorDiv = document.querySelector('.error-message');
        const errorResponse = error.error;

          // Assuming the error response is in the format you mentioned
          this.errorService.showErrorMessage(errorResponse);
        // Devuelve un observable de error para que no se propague el error no manejado
        return throwError(error);
      })
    );
  }
}
