import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 *
 *  The ErrorService class in TypeScript is an Injectable service that provides methods to show and hide
error messages.
 * @export
 * @class ErrorService
 */
@Injectable()
export class ErrorService {
  /**
   * The line `private errorMessageSource = new BehaviorSubject<any>('');` is creating a new instance of
 the `BehaviorSubject` class and assigning it to the `errorMessageSource` property.
   *
   * @private
   * @memberof ErrorService
   */
  private errorMessageSource = new BehaviorSubject<any>('');

  /**
   * `errorMessage$ = this.errorMessageSource.asObservable();` is creating an observable
  `errorMessage$` that emits values from the `errorMessageSource` BehaviorSubject. This allows other
  components or services to subscribe to `errorMessage$` and receive updates whenever the
  `errorMessageSource` emits a new value.
   *
   * @memberof ErrorService
   */
  errorMessage$ = this.errorMessageSource.asObservable();


  /**
   * The function "showErrorMessage" sends an error message to a specified source.
   * @param {any} message - The message parameter is of type any, which means it can accept any data
   * type.
   *
   * @param {*} message
   * @memberof ErrorService
   */
  showErrorMessage(message: any) {
    this.errorMessageSource.next(message);
  }


  /**
   * The function "hideErrorMessage" clears the error message.
   *
   * @memberof ErrorService
   */
  hideErrorMessage() {
    this.errorMessageSource.next('');
  }
}
